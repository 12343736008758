import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Box, Button, FormControlLabel, Radio, RadioGroup, THEME_ID, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import CreateIcon from '@mui/icons-material/Create'

import { useError, useGlossary, useTheme } from '@features/App/hooks'
import { Label, Text } from './StyledComponents'
import { EditAddress, NewAddress } from '@features/Addresses'
import { useAddress } from '@features/Addresses/hooks'
import { BlueButton, GreenButton } from '@features/App/Components'

/* eslint-disable max-len */
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))
  
const SectionLabel = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.gray[600],
}))

const RadioElement = styled(props => <Radio {...props} />)(({ theme }) => ({
  color: theme.palette.gray[600],
  '&.Mui-checked': {
    color: theme.palette.gray[600],
  },
}))

const AddressCard = styled(props => <Button {...props}/>, {
  shouldForwardProp: propName => (
    propName !== 'active'
  ),
})(({ theme, active = false }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  border: `2px solid ${active ? theme.palette.primary.main : theme.palette.gray[30]}`,
  color: theme.palette.gray[700],
  textTransform: 'capitalize',
  textAlign: 'left',
  alignItems: 'flex-start',
  borderRadius: theme.spacing(1),
  '& svg path': {
    fill: theme.palette.gray[500],
  },
}))

const AddressLabels = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  flexGrow: '1',
}))

const BoxAddress = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.down('md')]:{
    display: 'flex',
    flexDirection: 'column',
  }
}))
  
export const AddressStep = forwardRef(({ formik, changeHandlers  }, ref) => {

  const { logError } = useError()
  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const [addresses, setAddresses] = useState([])
  const [showSavedAddresses, setShowSavedAddresses] = useState(true)
  const [editAddress, setEditAddress] = useState(null)
  const { getUserAddresses } = useAddress()
  const editAddressRef = useRef(null)

  const { handleAddresses } = changeHandlers


  const getAddresses = async () => {
    try {
      const resp = await getUserAddresses({ fetchPolicy: 'network-only' })
      setAddresses(resp.userAddresses)
    } catch (error) {
      logError(error)
    }
  }

  useImperativeHandle(ref, () => ({
    async handleNext() {
      if ((editAddress || addresses.length === 0) && editAddressRef.current) {
        const result = await editAddressRef.current.submit()
        if (!result) return false
      }
      return true
    },
  }))


  const onSelectAddress = address => {
    formik.setFieldValue('pickupAddressId', address.id)
    formik.setFieldValue('pickupAddress', address)
  }

  useEffect(() => {
    getAddresses()
  }, [])

  useEffect(() => {
    if(formik.values.pickupAddress && formik.values.pickupAddress.id !== formik.values.pickupAddressId) {
      const selectedAddress = addresses.find(a => a.id === formik.values.pickupAddressId)
      if (selectedAddress) onSelectAddress(selectedAddress)
    }
    handleAddresses(addresses)
  }, [addresses])


  return <Container style={{ ...(isMd ?
    { flexDirection: 'row', width: '100%', padding: 0, gap: theme.spacing(2) } 
    : { width: '100%', padding: 0, paddingTop: '8px' } ), 
  }}>
    <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
      <Text sx={{ color: theme.palette.gray[600] }}>{glossary('PickupAddressDescription')}</Text>
      { addresses.length > 0 && showSavedAddresses && <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
        <BoxAddress>
        {
          addresses.map(_address => <AddressCard
                onClick={e => onSelectAddress(_address)}
                key={_address.id}
                active={_address.id === formik.values.pickupAddressId}
              >
                <AddressLabels>
                        <Box>
                          {_address.street} {_address.num}
                          {_address.numInt?.length > 1 ? ' ' + _address.numInt + ',' : ',' }
                        </Box>
                        <Box>
                          {_address.zipCode}, {_address.district}, {_address.locality}
                        </Box>
                      </AddressLabels>
                </AddressCard>)
        }
        </BoxAddress>
        <BlueButton sx={{ alignSelf: 'end'}}
        onClick={() => {
          setEditAddress(true)
          setShowSavedAddresses(false)
        }}>
          {glossary('AddAddress')}
        </BlueButton>
      </Container>
      }
      {
        (editAddress || addresses.length === 0) &&  <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}> 
            <NewAddress
            editingRef={editAddressRef}
            showLabel={false}
            onAdd={e => {
              formik.setFieldValue('pickupAddressId', e)
              formik.setFieldValue('pickupAddress', addresses.find(a => a === e))
              setEditAddress(false)
              setShowSavedAddresses(true)
            }}
            onAttachAddress={e => setAddresses(e)}
            // onSelectAddress={q => setShowSavedAddresses(!q)}
            />
           {addresses.length > 0 &&  <BlueButton sx={{ alignSelf: 'end'}}
             onClick={() => {
              setEditAddress(false)
              setShowSavedAddresses(true)
               }}>
                {glossary('SeeSavedAddresses')}
            </BlueButton> }
          </Container>
      }
    </Container>
  </Container>
})

export default AddressStep
