import { Avatar, Box, Chip, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useGlossary, useTheme } from '@features/App/hooks'
import { Label } from './StyledComponents'
import { CustomButton, CustomInput } from '@features/App/Components'
import { PlusIcon } from '@features/App/Components/Icons'

/* eslint-disable max-len */
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
}))

export const AddCoOwners = ({ coownersFormik, coOwnerFormik }) => {

  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  return <Container
    component='form'
    onSubmit={event => {
      event?.preventDefault()
      coOwnerFormik.handleSubmit()
    }}
    sx={{ ...(isMd ? {} : { flexDirection: 'column' }) }}
  >
    <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
      <Label>{ glossary('Name')}</Label>
      <CustomInput
        sx={{ gap: 0 }}
        placeholder={glossary('Name')}
        name={glossary('Name')}
        required
        onChange={e => coOwnerFormik.setFieldValue('name', e.target.value)}
        value={coOwnerFormik.values.name}
      />
      <Label style={{ marginTop: 8 }}>{glossary('Email')}</Label>
      <CustomInput
        sx={{ gap: 0 }}
        placeholder={glossary('Email')}
        name='email'
        required
        type='email'
        onChange={e => coOwnerFormik.setFieldValue('email', e.target.value)}
        value={coOwnerFormik.values.email}
      />
    </Container>
    <Container style={{ flexDirection: 'column', width: '100%', padding: 0, marginTop: 25, marginLeft: 15, ...(isMd ? {}: { marginLeft: '0px', marginTop:'10px' }) }}>
      <CustomButton type={'submit'} style={{ color: theme.palette.secondary.main, ...(isMd ? {} : { backgroundColor: theme.palette.gray[100], borderRadius: '0px', width: '100%', justifyContent: 'flex-start', padding: '16px' } ) }}>
        <span style={{ marginRight: 12 }}>
          <PlusIcon color={'#5062D3'} />
        </span>
        <Label style={{ textAlign: 'left', color: '#5062D3' }}>{glossary('AddOwner')}</Label>
      </CustomButton>
      <Container style={{ flexWrap: 'wrap', justifyContent: 'start', width: '100%' }}>
        {coownersFormik.values.coowners.map(q => (
          <Chip
            key={q}
            label={<Label>{q.name}</Label>}
            avatar={<Avatar>{q.name[0].toUpperCase()}</Avatar>}
            onDelete={() => {
              coownersFormik.setFieldValue('coowners', [...coownersFormik.values.coowners.filter(p => p !== q)])
            }}
          />
        ))}
      </Container>
    </Container>
  </Container>
}

export default AddCoOwners
