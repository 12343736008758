import React, { useEffect, useState, useReducer, forwardRef, useImperativeHandle } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel, Radio,
  RadioGroup,
  Slide,
  useMediaQuery
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Player, Controls } from '@lottiefiles/react-lottie-player'

import { useGlossary, useTheme, useVenueZone } from '@features/App/hooks'
import { CustomDivider, CustomInput, RedButton } from '@features/App/Components'
import { VenueAutocomplete, ZoneSelector } from '@features/App/Components/Forms'
import { VenueSectionSelect } from '@features/VenueMaps/VenueSelector'
import { Label, SecondLabel, Space } from './StyledComponents'

/* eslint-disable max-len */
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  [theme.breakpoints.up('md')]:{
    flexDirection: 'row',
  },
}))

const RadioElement = styled(props => <Radio {...props} />)(({ theme }) => ({
  color: theme.palette.secondary.main,
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
  [theme.breakpoints.up('md')]:{
    color: theme.palette.gray[600],
    '&.Mui-checked': {
      color: theme.palette.gray[600],
    },
  },
}))
  
const SectionLabel = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.gray[600],
}))

const CustomDialog = styled(props => <Dialog {...props} />)(({ theme }) => ({
  width: '100%',
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(1.5),
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      borderRadius: theme.spacing(0),
    },
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const MapContainerMobile = styled(props => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  height: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'sticky',
  top: '0',
  marginTop: 70,
}))

const Paragraph = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 12,
  color: theme.palette.secondary.main,
  paddingBottom: 8,
}))

const MapContainerDesktop = styled(props => <Box {...props} />)(({ theme }) => ({
  width: '50%',
  height: 350,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'sticky',
  top: '0',
  marginTop: 70,
}))

const customstyles ={
  backgroundColor: '#FFFFFF',
  '& legend': { display: 'none' },
  '& .MuiInputLabel-shrink': { opacity: 0, transition: 'all 0.2s ease-in', backgroundColor: '#FFFFFF' },
}

const sectionSelectionReducer = (state, action) => ({ ...state, ...action.payload })

/**
 * Renders a fragment with the form components to edit the following data about a property:
 *  - property type
 *  - forum
 *  - zone and section when there is a map
 *  - zone and section when there is no map
 *
 *  To do so, it requires a formik, which must have the following value fields:
 *  - type: String. A forum type id
 *  - forum: Forum object
 *  - rowName: String
 *  - zone: Zone object
 *  - section: Section object
 *  - noMap: Boolean. Whether the forum has a map
 *  - forumZoneNoMap: String. Name of the zone if there is no map.
 *  - forumSectionNoMap: String. Name of the section if there is no map.
 *
 *  The user will be able to manipulate this formik's values through this component, so that
 *  the parent component will only have to handle data validation and submission.
 *
 *  - **resetForm**: This component has available the resetForm function, which will reset all the
 *  values to the formik's initial values. resetForm can be accessed from a parent component
 *  through a ref, as follows: sectionSelectorRef.current.resetForm, given that sectionSelectorRef
 *  is pointing to an instance of this component (<SectionSelection ref={sectionSelectionRef} props/>).
 */
export const SectionSelector = forwardRef(function forumSelect ({
  formik,
  typeChangeCallback,
  forumChangeCallback,
  typeEditable=true,
  forumEditable=true,
}, ref) {

  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))
  const { glossary } = useGlossary()
  const { getVenueZones } = useVenueZone()

  const [showMap, setShowMap] = useState(false)
  const [venueMapKey, setVenueMapKey] = useState(1)

  const [sectionSelectionStore, sectionSelectionDispatch] = useReducer(sectionSelectionReducer, {
    selectedSection: formik?.values?.section,
    // zones is null because otherwise, as soon as ZoneSelector loads, it will show the empty state.
    // sections is [] because it is used in VenueSectionSelect.
    suiteInfo: { zones: null, sections: []},
    stallInfo: { zones: null, sections: []},
  })

  const { type, forum } = formik.values
  const { selectedSection } = sectionSelectionStore
  const availableZones =  type==='1' ? sectionSelectionStore.suiteInfo.zones : sectionSelectionStore.stallInfo.zones
  const availableSections =  type==='1' ? sectionSelectionStore.suiteInfo.sections : sectionSelectionStore.stallInfo.sections

  const fetchZonesData = venue => {
    if (!venue?.id) {
      return
    }
    const variables = {
      filter: {
        forum: [venue.id],
      },
      ord: ['name_es']
    }
    getVenueZones(variables, { fetchPolicy: 'network-only' }).then(resp => {
      const allSections = resp.edges
        .map(edge => edge.node)
        .map(q => q.sections)
        .flat()
      const suiteInfo = {
        sections: allSections.filter(s => s.typeId==='1'),
        zones: resp.edges.map(edge => edge.node).filter(z => z.sections?.some(s => s.typeId==='1')),
      }
      const stallInfo = {
        sections: allSections.filter(s => s.typeId==='2'),
        zones: resp.edges.map(edge => edge.node).filter(z => z.sections?.some(s => s.typeId==='2')),
      }
      sectionSelectionDispatch({
        payload: {
          suiteInfo,
          stallInfo,
        },
      })
    })
  }

  const remountMap = () => setVenueMapKey(venueMapKey + 1)

  const onTypeChange = event => {
    const { value } = event.target
    formik.setFieldValue('type', value)
    formik.setFieldValue('zone', undefined)
    formik.setFieldValue('section', undefined)
    sectionSelectionDispatch({
      payload: {
        selectedSection: null,
      },
    })
    if(typeChangeCallback) typeChangeCallback(value)
  }

  const onForumChange = newForum => {
    formik.setFieldValue('forum', newForum)
    formik.setFieldValue('zone', undefined)
    formik.setFieldValue('section', undefined)
    formik.setFieldValue('forumSectionNoMap', undefined)
    formik.setFieldValue('forumZoneNoMap', undefined)
    if (newForum) {
      formik.setFieldValue('noMap', newForum.stadibox_map === null)
    }
    sectionSelectionDispatch({
      payload: {
        selectedSection: null,
      },
    })
    remountMap()
    if(forumChangeCallback) forumChangeCallback(newForum)
  }

  const onMapSectionSelection = section => {
    if(!availableZones) return
    const zone = availableZones.find(q => q.sections.includes(section))
    formik.setFieldValue('zone', zone)
    formik.setFieldValue('section', section)
    sectionSelectionDispatch({
      payload: {
        selectedSection: section,
      },
    })
  }

  const onZoneSelectorSectionChange = section => {
    formik.setFieldValue('section', section)
    sectionSelectionDispatch({
      payload: {
        selectedSection: section,
      },
    })
  }

  const resetForm = () => {
    formik.resetForm()
    sectionSelectionDispatch({
      payload: {
        selectedSection: formik.initialValues.section,
      },
    })
  }

  useImperativeHandle(ref, () => {
    return {
      resetForm,
    }
  }, [])

  useEffect(() => {
    fetchZonesData(formik.values.forum)
  }, [])

  useEffect(() => {
    fetchZonesData(forum)
  }, [forum])


  return <>
    { typeEditable && <>
      <Container>
        <SectionLabel>{glossary('PropertyType')}</SectionLabel>
      </Container>
      <Container style={{ flexDirection: 'column', width: '100%', paddingTop: 0 }}>
        <RadioGroup
          row
          name='type'
          value={formik.values.type}
          onChange={onTypeChange}
        >
          <FormControlLabel value='1' control={<RadioElement />} label={<SectionLabel>{glossary('Boxes')}</SectionLabel>}/>
          <FormControlLabel value='2' control={<RadioElement />} label={<SectionLabel>{glossary('Stalls')}</SectionLabel>} />
        </RadioGroup>
      </Container>
    </>
    }
    { forumEditable &&
      <Container>
        <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
          <Label>{glossary('Venue')}</Label>
          <VenueAutocomplete
            venue={formik.values.forum}
            setVenue={onForumChange}
            customstyles={customstyles}
            error={Boolean(formik.errors.forum)}
          />
        </Container>
        {
          isMd && <Space/>
        }
      </Container>
    }
    {formik.values.forum && (
      <Container>
        <Container style={{ flexDirection: 'column', width: isMd ? '50%' : '100%', padding: 0 }}>
          {formik.values.forum.stadibox_map 
            ? (
              <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
                <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
                  <Label>{glossary('PropertysNumberName')}</Label>
                  <CustomInput
                    onChange={e => formik.setFieldValue('rowName', e.target.value)}
                    name='rowName'
                    value={formik.values.rowName}
                    error={Boolean(formik.errors.rowName)}
                    sx={{ gap: 0 }}
                    placeholder={glossary('PropertysNumberName')}
                    inputProps={{ maxLength: 50 }}
                  />
                </Container>
                <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
                  { !isMd && <CustomDivider sx={{ width: '100%', marginTop: '16px', marginBottom: '12px' }}/>}
                  {!isMd && <SecondLabel>{glossary('ZoneWhereIsLocated')}</SecondLabel>}
                  { isMd && <Label sx={{ marginTop: '10px' }}>{glossary('Zone')}</Label>}
                  { !isMd && <Paragraph onClick={() => setShowMap(true)}>
                    { glossary('SelectOnMap')}
                  </Paragraph>
                  }
                  <ZoneSelector
                    venue={formik.values.forum}
                    type={formik.values.type}
                    zone={formik.values.zone}
                    setZone={e => formik.setFieldValue('zone', e)}
                    section={formik.values.section}
                    setSection={onZoneSelectorSectionChange}
                    zones={availableZones}
                    sections={availableSections}
                    error={Boolean(formik.errors.section)}
                    error1={Boolean(formik.errors.zone)}
                  />
                </Container>
              </Container>) 
            : (
              <>
                <SectionLabel>
                  { glossary('NewMapAddInfo') }
                </SectionLabel>
                <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
                  <Label>{glossary('PropertysNumberName')}</Label>
                  <CustomInput
                    onChange={e => formik.setFieldValue('rowName', e.target.value)}
                    name='rowName'
                    value={formik.values.rowName}
                    error={Boolean(formik.errors.rowName)}
                    inputProps={{ maxLength: 50 }}
                    sx={{ gap: 0 }}
                    placeholder={glossary('PropertysNumberName')}
                  />
                </Container>
                <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
                  <Label>{glossary('Zone')}</Label>
                  <CustomInput
                    onChange={e => formik.setFieldValue('forumZoneNoMap', e.target.value)}
                    name='forumZoneNoMap'
                    value={formik.values.forumZoneNoMap}
                    error={Boolean(formik.errors.forumZoneNoMap)}
                    inputProps={{ maxLength: 48 }}
                    sx={{ gap: 0 }}
                    placeholder={glossary('Zone')}
                  />
                </Container>
                <Container style={{ flexDirection: 'column', width: '100%', padding: 0 }}>
                  <Label>{glossary('Section')}</Label>
                  <CustomInput
                    onChange={e => formik.setFieldValue('forumSectionNoMap', e.target.value)}
                    name='forumSectionNoMap'
                    value={formik.values.forumSectionNoMap}
                    error={Boolean(formik.errors.forumSectionNoMap)}
                    inputProps={{ maxLength: 48 }}
                    sx={{ gap: 0 }}
                    placeholder={glossary('Section')}
                  />
                </Container>
              </>
            )}
        </Container>
        { (isMd && formik.values.forum && formik.values.forum.stadibox_map ) 
          ? (
            <MapContainerDesktop id='map-container' key={venueMapKey}>
              <VenueSectionSelect
                forum={formik.values.forum}
                onChange={onMapSectionSelection}
                sections={availableSections}
                selectedSection={selectedSection}
              />
            </MapContainerDesktop>
          ) 
          : (
            isMd && <Container style={{ justifyContent: 'center', width: '100%', padding: 0 }}>
              <Player autoplay loop src='/animations/Construction.json' style={{ height: '250px', width: '250px' }}>
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
              </Player>
            </Container>
          )}
      </Container>
    )}      
    {showMap && (
      <CustomDialog
        open={showMap}
        onClose={() => setShowMap(false)}
        fullWidth
        maxWidth='xs'
        TransitionComponent={Transition}
      >
        <DialogContent sx={{ paddingBottom: '0px', padding: '16px' }}>
          <MapContainerMobile id='map-container' key={venueMapKey}>
            <VenueSectionSelect
              forum={formik.values.forum}
              onChange={onMapSectionSelection}
              sections={availableSections}
              selectedSection={selectedSection}
            />
          </MapContainerMobile>
        </DialogContent>
        <DialogActions sx={{ padding: '16px', paddingBottom: '24px' }}>
          <RedButton onClick={() => setShowMap(false)}>{ glossary('Close') }</RedButton>
        </DialogActions>
      </CustomDialog>
    )}
  </>
})

export default SectionSelector
