import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import dynamic from 'next/dynamic'
import NoMap from './NoMap'
import { useVenueZone } from '@features/App/hooks'

// #region Styles

const MapContainer = styled(props => <Box {...props} />)(({ theme, mono }) => ({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  '& svg': {
    height: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  fill: '#E2E2E2',
  '& g': {
    '&.available': {
      cursor: 'pointer',
      transition: '0.2s all ease-out',
      '&.active': {
        opacity: '1',
      },
      '&.inactive': {
        opacity: '0.3',
        '&:hover': {
          opacity: '0.5',
        },
      },
    },
  },
  '& .white, .text': {
    fill: '#FEFEFE',
  },
  '& .stroke-white': {
    stroke: '#FEFEFE',
  },
  '& .grass': {
    fill: '#7EB168',
  },
  '& .light-green': {
    fill: '#BFD8B4',
  },
  '& .dark': {
    fill: '#4D4D4D',
  },
  '& .invisible': {
    opacity: '0',
  },
  '& .brown': {
    fill: '#8E745B',
  },
  touchAction: 'manipulation',
}))

const Clean = styled(props => <Box {...props} />)(({ theme }) => ({
  position: 'absolute',
  alignSelf: 'flex-start',
  justifySelf: 'flex-end',
  cursor: 'pointer',
  textDecoration: 'underline',
  paddingRight: theme.spacing(3),
  color: theme.palette.secondary.main,
}))

// #endregion

const NoMapAvailable = () => {
  return <NoMap />
}

const VenueSectionPicker = ({ forum, onChange, handleClean, availableSections = [], selectedSections = [] }) => {
  const { getVenueZones } = useVenueZone()
  const [sections, setSections] = useState([])

  const mapName = forum?.stadibox_map?.mapFile ?? ''
  const [Map, setMap] = useState(null)

  const fetchZonesData = venue => {
    if (!venue?.id) {
      return
    }
    const variables = {
      filter: {
        forum: [venue.id],
      },
    }
    getVenueZones(variables, { fetchPolicy: 'network-only' }).then(resp => {
      const allSections = resp.edges
        .map(edge => edge.node)
        .map(q => q.sections)
        .flat()
      setSections(allSections)
    })
  }

  const handleClick = useCallback(
    e => {
      const group = e.target.parentElement
      if (!Map) return
      if (group.id === '') return
      if (availableSections.indexOf(group.id) === -1) return
      onChange(group.id)
    },
    [onChange, Map, availableSections, selectedSections]
  )

  useLayoutEffect(() => {
    if (!Map) return
    const size = availableSections.length
    if (size === 0) return
    setTimeout(() => {
      let num = 0
      const elementsG = document.querySelectorAll('g[id]')
      elementsG.forEach(elementG => {
        const _num = parseInt(elementG.getAttribute('num'))
        if (!isNaN(_num) && _num > num) num = _num
      })

      const pathClearSections = document.querySelectorAll('g[id] path')
      const polygonClearSections = document.querySelectorAll('g[id] polygon')
      const rectClearSections = document.querySelectorAll('g[id] rect')

      const clearSections = [...pathClearSections, ...rectClearSections, ...polygonClearSections]

      clearSections.forEach(section => section.setAttribute('fill', '#E2E2E2'))

      // const colors = chroma
      //   .scale(MAP_COLORS)
      //   .mode('lch')
      //   .colors(num < 1 ? size : num)
      if (sections.length > 0) {
        for (const x in availableSections) {
          const _group = availableSections[x]
          console.log('_group', _group)
          const groupElement = document.querySelector(`g[id="${_group}"]`)
          if (groupElement) groupElement.setAttribute('class', 'available')
          const pathElements = document.querySelectorAll(`g[id="${_group}"] path`)
          const polygonElements = document.querySelectorAll(`g[id="${_group}"] polygon`)
          const rectElements = document.querySelectorAll(`g[id="${_group}"] rect`)
          const elements = [...pathElements, ...rectElements, ...polygonElements]
          console.log('sections', sections)

          elements.forEach(element => {
            element.setAttribute(
              'fill',
              `#${sections.find(s => s.id === _group).color}`
              // colors[groupElement.getAttribute('num') ? groupElement.getAttribute('num') - 1 : x]
            )
          })
        }
      }
    }, 1000)
  }, [Map, availableSections, sections])

  useEffect(() => {
    if (Map) return
    const dynamicMap = dynamic(() =>
      import(`./../Maps/${mapName}`)
        .then(module => module.default)
        .catch(() => NoMapAvailable)
    )
    setMap(() => dynamicMap)
  }, [mapName])

  useEffect(() => {
    if (selectedSections.length > 0) {
      for (const section of availableSections) {
        const group = document.querySelector(`g[id="${section}"]`)
        if (!group) return
        const cl = group.classList
        cl.add('inactive')
        cl.remove('active')
      }
      for (const section of selectedSections) {
        const group = document.querySelector(`g[id="${section}"]`)
        if (!group) return
        const cl = group.classList
        cl.add('active')
        cl.remove('inactive')
      }
    } else {
      for (const section of availableSections) {
        const group = document.querySelector(`g[id="${section}"]`)
        if (!group) return
        const cl = group.classList
        cl.remove('active')
        cl.remove('inactive')
      }
    }
  }, [selectedSections])

  useEffect(() => {
    console.log('done')

    fetchZonesData(forum)
  }, [])

  useEffect(() => {
    console.log(sections)
  }, [sections])

  return (
    <MapContainer onClick={handleClick}>
      {selectedSections.length > 0 && <Clean onClick={handleClean}>Borrar Selección</Clean>}
      {Map && <Map />}
    </MapContainer>
  )
}

export default VenueSectionPicker
