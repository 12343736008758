import { Box, Checkbox } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Label = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.gray[600],
}))

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))

export const SectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  paddingTop: theme.spacing(2),
  gap: theme.spacing(1),
  width: '100%',
}))

export const SectionTitle = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.rubik.subtitle.medium,
  fontWeight: '500',
  fontSize: 24,
  color: theme.palette.gray[600],
}))

export const Space = styled(Box)(({ theme }) => ({
  width: '100%',
}))

export const Text = styled('label')(({ theme, error }) => ({
  font: theme.font.rubik.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: error ? 'red' : theme.palette.gray[700],
}))

export const Paragraph = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 12,
  color: theme.palette.gray[500],
}))

export const SectionLabel = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.gray[600],
}))

export const CheckboxElement = styled(props => <Checkbox {...props} />)(({ theme, error }) => ({
  color: error ? 'red' : theme.palette.gray[500],
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
}))

export const SecondLabel = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.rubik.paragraph.large,
  fontWeight: '500',
  fontSize: 16,
  color: theme.palette.gray[600],
}))

export const SectionTitleMobile = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.rubik.paragraph.medium,
  fontWeight: '500',
  fontSize: 16,
  color: theme.palette.gray[600],
}))

export const ActionsContainer = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 70,
  backgroundColor: theme.palette.background.default,
}))

export const ActionsContent = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 10,
  width: '100%',
  backgroundColor: theme.palette.background.default,
  paddingLeft: 10,
  paddingRight: 10,
}))

export const BoxContainer = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
}))

export const BoxContent = styled(props => <Box {...props} />)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
}))

export const TextMobile = styled('label')(({ theme }) => ({
  font: theme.font.rubik.paragraph.small,
  fontWeight: '400',
  fontSize: 14,
  color: theme.palette.gray[700],
}))

