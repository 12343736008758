import { useEffect, useState } from 'react'
import { Box, FormControlLabel, TextField, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useAmenities, useGlossary, useTheme } from '@features/App/hooks'
import { CheckboxElement, SectionLabel } from './StyledComponents'

/* eslint-disable max-len */
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))

export const AmenitiesSelect = ({ formik, amenityHandler }) => {
  const { getAmenities } = useAmenities()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const [amenityList, setAmenityList] = useState([])

  const loadAmenities = () => {
    try {
      getAmenities({}).then(resp => {
        const other = resp.find(q => q.name_en === 'Other')
        const amenities = resp.filter(q => q.name_en !== 'Other')

        setAmenityList([...amenities, other])
        if (amenityHandler) amenityHandler(resp)
      })
    } catch (error) {
      logError(error)
    }
  }

  useEffect(() => {
    loadAmenities()
  }, [])

  return (
    <Container style={{ width: '100%' }}>
      {!isMd &&
        amenityList.map((q, i) => (
          <Box key={q} sx={{ width: '100%' }}>
            {i === amenityList.length - 1 ? (
              <Container style={{ flexDirection: 'row', width: '100%', padding: 0 }}>
                <FormControlLabel
                  control={
                    <CheckboxElement
                      sx={{ paddingBottom: 0 }}
                      onChange={e => {
                        if (e.target.checked) {
                          formik.setFieldValue('amenities', [...formik.values.amenities, q])
                        } else {
                          formik.setFieldValue(
                            'amenities',
                            formik.values.amenities.filter(p => p.id !== q.id)
                          )
                        }
                      }}
                      checked={formik.values.amenities.map(a => a.id).includes(q.id)}
                      error={Boolean(formik.errors.amenities)}
                    />
                  }
                  sx={{ alignItems: 'end' }}
                  label={<SectionLabel> {q.name} </SectionLabel>}
                />
              </Container>
            ) : (
              <FormControlLabel
                control={
                  <CheckboxElement
                    sx={{ paddingBottom: 0 }}
                    onChange={e => {
                      if (e.target.checked) {
                        formik.setFieldValue('amenities', [...formik.values.amenities, q])
                      } else {
                        formik.setFieldValue(
                          'amenities',
                          formik.values.amenities.filter(p => p.id !== q.id)
                        )
                      }
                    }}
                    checked={formik.values.amenities.map(a => a.id).includes(q.id)}
                    error={Boolean(formik.errors.amenities)}
                  />
                }
                sx={{ alignItems: 'end' }}
                label={<SectionLabel>{q.name}</SectionLabel>}
              />
            )}
          </Box>
        ))}
      {isMd && (
        <Container sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)', 
          gap: theme.spacing(2), 
        }} >
          {amenityList.map(q => (
            <FormControlLabel
              key={q}
              control={
                <CheckboxElement
                  sx={{ paddingBottom: 0 }}
                  onChange={e => {
                    if (e.target.checked) {
                      formik.setFieldValue('amenities', [...formik.values.amenities, q])
                    } else {
                      formik.setFieldValue(
                        'amenities',
                        formik.values.amenities.filter(p => p.id !== q.id)
                      )
                    }
                  }}
                  checked={formik.values.amenities.map(a => a.id).includes(q.id)}
                  error={Boolean(formik.errors.amenities)}
                />
              }
              label={<SectionLabel>{q.name} </SectionLabel>}
              sx={{ flex: '1 1 25%', alignItems: 'end'}}
            />
          ))}
        </Container>
      )}
    </Container>
  )
}

export default AmenitiesSelect
