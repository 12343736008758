import { Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useGlossary, useTheme } from '@features/App/hooks'
import { Paragraph, SectionTitle, Space, Text } from './StyledComponents'
import { NumberCounter } from '@features/App/Components'
import { CustomDivider } from '@App/Components'

/* eslint-disable max-len */
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))
  
export const TicketsCounter = ({ type, formik }) => {

  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md')) 

  return (
    <Container sx={{ ...(isMd ? { gap: '22px', flexDirection: 'row', width: '100%', padding: '0px' }: { padding: 0, width: '100%' } ) }}>
      <Container style={{ flexDirection: 'row', width: '100%', paddingTop: 10, paddingBottom: 10 }}>
        <Text sx={{ color: formik.errors.seatNumber ? 'red' : 'inherit' }}>{glossary('TicketsAmount')}</Text>
        <NumberCounter
          min={0}
          max={100}
          number={formik.values.seatNumber}
          onChange={q => formik.setFieldValue('seatNumber', q)}
          error={Boolean(formik.errors.seatNumber)}
        />
      </Container>
      { !isMd &&  <CustomDivider sx={{ width: '100%' }} />}
      <Container sx={{ ...(isMd ? { flexDirection: 'column', gap: '10px', width: '100%', padding: 0 }: { padding: 0, width: '100%' }) }}>
        <Container
          style={{ flexDirection: 'row', alignItems: 'center', width: '100%', paddingTop: 10, paddingBottom: 10 }}
        >
          <Text>{glossary('Parking')}</Text>
          <NumberCounter
            min={0}
            max={100}
            number={formik.values.parkingSpaces}
            onChange={q => formik.setFieldValue('parkingSpaces', q)}
          />
        </Container>
        { !isMd &&  <CustomDivider sx={{ width: '100%' }} />}
        {type === '1' && (
          <Container
            style={{ flexDirection: 'row', alignItems: 'center', width: '100%', paddingTop: 10, paddingBottom: 10 }}
          >
            <Container style={{ flexDirection: 'column', padding: 0, gap: 4 }}>
              <Text>{glossary('VIPParking')}</Text>
              <Paragraph>{glossary('VIPDescription')}</Paragraph>
            </Container>
            <NumberCounter
              min={0}
              max={100}
              number={formik.values.vipParkingSpaces}
              onChange={q => formik.setFieldValue('vipParkingSpaces', q)}
            />
          </Container>
        )}
      </Container>
    </Container>
  )
}

export default TicketsCounter
