import { Box, FormControlLabel, Radio, RadioGroup, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useGlossary, useTheme } from '@features/App/hooks'
import { SectionLabel } from './StyledComponents'

/* eslint-disable max-len */
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}))
  
const RadioElement = styled(props => <Radio {...props} />)(({ theme }) => ({
  color: theme.palette.secondary.main,
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
}))
  
const Paragraph = styled('label')(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: '400',
  fontSize: 12,
  color: theme.palette.gray[500],
  [theme.breakpoints.up('md')]:{
    font: theme.font.rubik.paragraph.small,
    fontWeight: '400',
    color: theme.palette.gray[600],
  },
}))

export const TicketDeliveryMethod = ({ store, formik }) => {

  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md')) 

  const handleChange = event => {
    const { name, value } = event.target
    formik.setFieldValue(name, value)
  }

  return <Container style={{ ...( !isMd ? { flexDirection: 'column', width: '100%', paddingTop: 0 } : { flexDirection: 'row' }) }}>
    <RadioGroup
      row
      name='ticketDeliveryMethod'
      value={formik.values.ticketDeliveryMethod}
      onChange={handleChange}
      sx={{ ...(isMd
        ? { width:'100%' }
        : {}) }}
    >
      <FormControlLabel value='digital' control={<RadioElement />} label={<SectionLabel>{glossary('DigitalTickets')}</SectionLabel>}/>
      <FormControlLabel value='physical' control={<RadioElement />} label={<SectionLabel>{glossary('PhysicalTickets')}</SectionLabel>}/>
    </RadioGroup>
    <Paragraph sx={{ ...(isMd ? { width: '80%' }: {} ) }}>
      {glossary(formik.values.ticketDeliveryMethod==='digital' ? 'ElecTicketDesc' : 'PsycTicketDesc')}
    </Paragraph>
  </Container>
}

export default TicketDeliveryMethod
