import { useRouter } from 'next/router'
import { styled } from '@mui/material/styles'
import { Box, CardMedia, useMediaQuery } from '@mui/material'

import { useGlossary, useTheme } from '@features/App/hooks'
import { GreenButton, GreenOutlinedButton } from '@App/Components'

const Root = styled(props => <Box {...props} />)(({ theme }) => ({
  width: 'min(750px, 100%)',
  backgroundColor: theme.palette.gray[100],
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows.elevation.medium,
  padding: theme.spacing(2),
  marginTop: 44,
  marginBottom: 44,
  [theme.breakpoints.down('md')]:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    marginTop: 0,
    marginBottom: 0,
  },
}))

const ContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(4.5),
  [theme.breakpoints.down('md')]:{
    gap: 10,
    height: '100%',
    padding: 0,
  },
}))

const MainLabel = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  fontWeight: '600',
  fontSize: 16,
  color: theme.palette.gray[600],
}))

const Label = styled(Box)(({ theme }) => ({
  font: theme.font.rubik.paragraph.medium,
  fontSize: 14,
  color: theme.palette.gray[600],
  textAlign: 'center', 
  marginBottom: 20,
  padding: 16,
  [theme.breakpoints.up('md')]:{
    width: '70%',
  },
}))

export const Complete = ({}) => {

  const router = useRouter()
  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const height = isMd ? 200 : 100

  return (
    <Root>
      <ContainerBox>
        <MainLabel> {glossary('WeAreGlad')} </MainLabel>
        <Label>
          {glossary('WeAreGladDescription')}
        </Label>
        <CardMedia component='img' image='/svg/success.svg' height={height}
          sx={{ marginBottom: 2, objectFit: 'contain' }} />
        <GreenButton style={{ ...(isMd ? { width: '70%', height: 40 } : { width: '80%', height: 30 }) }}
          onClick={() => router.push('/dashboard/userProperties')}>
          { glossary('SeeProperties')}
        </GreenButton>
        <GreenOutlinedButton style={{ ...(isMd ? { width: '70%', height: 40 } : { width: '80%', height: 30 }) }}
          onClick={() => router.push('/')}>
          {glossary('ReturnToHome')}</GreenOutlinedButton>
      </ContainerBox>
    </Root>
  )
}

export default Complete
