import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import dynamic from 'next/dynamic'

import NoMap from './NoMap'

const MapContainer = styled(props => <Box {...props} />)(({ theme, mono }) => ({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  '& svg': {
    height: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  fill: '#E2E2E2',
  '& g': {
    '&.available': {
      cursor: 'pointer',
      transition: '0.2s all ease-out',
      '&.active': {
        opacity: '1',
      },
      '&.inactive': {
        opacity: '0.3',
        '&:hover': {
          opacity: '0.5',
        },
      },
    },
  },
  '& .white, .text': {
    fill: '#FEFEFE',
  },
  '& .stroke-white': {
    stroke: '#FEFEFE',
  },
  '& .grass': {
    fill: '#7EB168',
  },
  '& .light-green': {
    fill: '#BFD8B4',
  },
  '& .dark': {
    fill: '#4D4D4D',
  },
  '& .invisible': {
    opacity: '0',
  },
  '& .brown': {
    fill: '#8E745B',
  },
  touchAction: 'manipulation',
}))

const NoMapAvailable = () => {
  return <NoMap />
}

const VenueSectionSelect = ({ forum, onChange, sections, selectedSection }) => {
  const mapName = forum?.stadibox_map?.mapFile ?? ''
  const [Map, setMap] = useState(null)
  const [init, setInit] = useState(false)

  const clearAllSections = () => {
    const mapContainerElem = document.querySelector('#venue-section-select-map')
    const pathClearSections = mapContainerElem.querySelectorAll('g[id] path')
    const polygonClearSections = mapContainerElem.querySelectorAll('g[id] polygon')
    const rectClearSections = mapContainerElem.querySelectorAll('g[id] rect')
    const clearSections = [...pathClearSections, ...rectClearSections, ...polygonClearSections]
    clearSections.forEach(section => section.setAttribute('fill', '#E2E2E2'))
  }

  const paintAvailableSections = sections => {
    const mapContainerElem = document.querySelector('#venue-section-select-map')
    for (const x in sections) {
      const _group = sections[x].id
      const groupElement = mapContainerElem.querySelector(`g[id="${_group}"]`)
      if (groupElement) {
        groupElement.setAttribute('class', 'available')
      }
      const pathElements = mapContainerElem.querySelectorAll(`g[id="${_group}"] path`)
      const polygonElements = mapContainerElem.querySelectorAll(`g[id="${_group}"] polygon`)
      const rectElements = mapContainerElem.querySelectorAll(`g[id="${_group}"] rect`)
      const elements = [...pathElements, ...rectElements, ...polygonElements]
      elements.forEach(element => {
        element.setAttribute('fill', `#${sections[x].color}`)
      })
    }
  }

  const handleSelect = () => {
    const mapContainerElem = document.querySelector('#venue-section-select-map')
    if (selectedSection) {
      for (const section of sections) {
        const group = mapContainerElem.querySelector(`g[id="${section.id}"]`)
        if (group) {
          const cl = group.classList
          cl.add('inactive')
          cl.remove('active')
        }
      }
      const group = mapContainerElem.querySelector(`g[id="${selectedSection.id}"]`)
      if (group) {
        const cl = group.classList
        cl.add('active')
        cl.remove('inactive')
      }
    } else {
      for (const section of sections) {
        const group = mapContainerElem.querySelector(`g[id="${section.id}"]`)
        if (group) {
          const cl = group.classList
          cl.add('active')
          cl.remove('inactive')
        }
      }
    }
  }

  const handleClick = useCallback(
    e => {
      const group = e.target.parentElement
      if (!Map) {
        return
      }
      if (group.id === '') {
        return
      }
      if (sections.map(q => q.id).indexOf(group.id) === -1) {
        return
      }
      const section = sections.find(q => q.id === group.id)
      onChange(section)
    },
    [onChange, Map, sections]
  )

  useLayoutEffect(() => {
    if (!Map) {
      return
    }

    setTimeout(() => {
      let num = 0
      const mapContainerElem = document.querySelector('#venue-section-select-map')
      const elementsG = mapContainerElem.querySelectorAll('g[id]')
      elementsG.forEach(elementG => {
        const _num = parseInt(elementG.getAttribute('num'))
        if (!isNaN(_num) && _num > num) num = _num
      })

      clearAllSections()
      paintAvailableSections(sections)
      handleSelect()
      setInit(true)
    }, 1000)
  }, [Map, sections])

  useEffect(() => {
    if (Map) {
      return
    }
    const dynamicMap = dynamic(() =>
      import(`./../Maps/${mapName}`)
        .then(module => module.default)
        .catch(() => NoMapAvailable)
    )
    setMap(() => dynamicMap)
  }, [mapName])

  useEffect(() => {
    if (init) {
      handleSelect()
    }
  }, [selectedSection])

  return (
    <MapContainer onClick={handleClick} id={'venue-section-select-map'}>
      {Map && <Map />}
    </MapContainer>
  )
}

export default VenueSectionSelect
