import Complete from './Complete'
import SectionSelector from './SectionSelector'
import TicketsCounter from './TicketsCounter'
import TicketDeliveryMethod from './TicketDeliveryMethod'
import AmenitiesSelect from './AmenitiesSelect'
import AddCoOwners from './AddCoOwners'
import AddressStep from './AddressStep'

export {
  Complete,
  SectionSelector,
  TicketsCounter,
  TicketDeliveryMethod,
  AmenitiesSelect,
  AddCoOwners,
  AddressStep,
}

export default {
  Complete,
  SectionSelector,
  TicketsCounter,
  TicketDeliveryMethod,
  AmenitiesSelect,
  AddCoOwners,
  AddressStep,
}
